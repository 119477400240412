/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

@use '../src/app/core/styles/themes';

.fuse-horizontal-navigation-item-title {
    color: themes.$color-brand-colors-main-lightest !important;
}

.fuse-horizontal-navigation-item-active {
    .fuse-horizontal-navigation-item-title {
        color: white;
    }
}

.k-sidebar {
    @media screen and (max-width: 600px) {
        width: 100% !important;
        min-width: 100% !important;
    }
}
