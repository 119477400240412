@use '../themes';

.input-tags {
    width: 100%;

    ::ng-deep {
        input {
            margin: 0px !important;
            font-size: 12px;
        }
    }
}

.mdc-evolution-chip-set .mdc-evolution-chip-set__chips {
    margin: 0 !important;
    column-gap: 6px;
    row-gap: 6px;
}

.mat-mdc-chip {
    background-color: themes.$color-neutral-lightest !important;
    color: themes.$color-neutral-darker;

    font-weight: themes.$semiBold !important;
    font-size: 12px !important;
    text-transform: uppercase;
    letter-spacing: 0;
    line-height: 10px !important;

    display: flex;
    flex-direction: row;
    align-content: flex-start;
    justify-content: center;
    align-items: center;

    padding: 0 5px;
    border-radius: 24px;

    button {
        border: 0;
        outline: none;
        background: themes.$color-neutral-default;
        width: 16px;
        height: 16px;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        ::ng-deep mat-icon {
            color: white !important;
            $iconSize: 10px;
            width: $iconSize;
            min-width: $iconSize;
            height: $iconSize;
            min-height: $iconSize;
            font-size: $iconSize;
            line-height: $iconSize;
        }
    }
}

mat-chip-list:focus {
    outline: 0;
}
