@use '../themes';

.avatar-laboratory {
    .avatar-content {
        border-radius: 4px !important;
    }
}

ngx-avatars {
    .avatar-content {
        background-color : themes.$color-brand-colors-secondary-default !important;
        color: #FFFFFF !important;
        font-family: themes.$inter !important;
        font-weight: themes.$semiBold !important;

        max-width: 100%;
        max-height: 100%;
    }

    &.bg-transparent {
        .avatar-content {
            background-color: transparent !important;
        }
    }

    &:not(.font-original) {
        .avatar-content {
            //font-size: 14px !important;
            font-size: min(3vw, 13px) !important;
            font-weight: themes.$semiBold !important;
        }
    }

    &.big {
        .avatar-content {
            font-size: 50px !important;
        }
    }

    &.small {
        .avatar-content {
            font-size: 11px !important;
        }
    }

    img {
        display: initial;
    }
}

.avatar {
    width: 40px;
    min-width: 40px;
    height: 40px;
    line-height: 40px;
    margin: 0 8px 0 0;
    border-radius: 50%;
    font-size: 17px;
    font-weight: 600;
    text-align: center;

    &.square {
        border-radius: 0;
    }

    &.small {
        width: 20px;
        min-width: 20px;
        height: 20px;
        line-height: 20px;
    }

    &.regular {
        width: 30px;
        min-width: 30px;
        height: 30px;
        line-height: 30px;
    }

    &.big {
        width: 72px;
        min-width: 72px;
        height: 72px;
        line-height: 72px;
    }

    &.huge {
        width: 96px;
        min-width: 96px;
        height: 96px;
        line-height: 96px;
    }
}

.avatar-wrapper {
    position: relative;

    .avatar {
        margin-top: 0;
        margin-bottom: 0;
    }
    mat-icon.status {
        position: absolute;
        top: 28px;
        left: 28px;
    }
}
