$inter: 'Inter', sans-serif;
$thin: 100;
$extraLight: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semiBold: 600;
$bold: 700;
$extraBold: 800;
$black: 900;

// Brand
$color-brand-colors-main-darkest: #122D42;
$color-brand-colors-main-dark: #235881;
$color-brand-colors-main-darker: #158fef;
$color-brand-colors-main-default: #44AEFF;
$color-brand-colors-main-lighter: #7CC6FF;
$color-brand-colors-main-light: #B4DEFF;
$color-brand-colors-main-lightest: #ECF7FF;
$color-brand-colors-main-lightest-white: #F9FCFF;
$color-brand-colors-secondary-darkest: rgba(34,29,63,1);
$color-brand-colors-secondary-dark: rgba(67,57,124,1);
$color-brand-colors-secondary-darker: rgba(99,85,184,1);
$color-brand-colors-secondary-default: rgba(132,113,244,1);
$color-brand-colors-secondary-lighter: rgba(165,151,247,1);
$color-brand-colors-secondary-light: rgba(198,189,250,1);
$color-brand-colors-secondary-lightest: rgba(230,227,253,1);

// UI
$color-ui-success-darkest: #153010;
$color-ui-success-dark: #295D1E;
$color-ui-success-darker: #3C8B2D;
$color-ui-success-default: #50B83C;
$color-ui-success-lighter: #7FCB70;
$color-ui-success-light: #ADDEA4;
$color-ui-success-lightest: #DCF1D8;
$color-ui-error-darkest: #410E0E;
$color-ui-error-dark: #7F1C1B;
$color-ui-error-darker: #BD2928;
$color-ui-error-default: #FB3735;
$color-ui-error-lighter: #FC6C6B;
$color-ui-error-light: #FDA2A1;
$color-ui-error-lightest: #FED7D7;
$color-ui-warning-darkest: #423305;
$color-ui-warning-dark: #81630A;
$color-ui-warning-darker: #C0930F;
$color-ui-warning-default: #FFC314;
$color-ui-warning-lighter: #FFD353;
$color-ui-warning-light: #FFE391;
$color-ui-warning-lightest: #FFF3D0;

// Neutral
$color-neutral-darkest: #0C0E10;
$color-neutral-dark: #2F383D;
$color-neutral-darker: #556168;
$color-neutral-default: #7D8A91;
$color-neutral-lighter: #A2AEB4;
$color-neutral-light: #C8D1D6;
$color-neutral-lightest: #F1F4F5;
$color-neutral-white: #FFFFFF;


.font-typography-text-big {
    font-size: 24px;
    font-family: $inter;
    font-weight: $regular;
}

.font-typography-text-medium {
    font-size: 13px;
    font-family: $inter;
    font-weight: $medium;
}

.font-typography-text-small {
    font-size: 13px;
    font-family: $inter;
    font-weight: $semiBold;
}

.font-typography-text-small-uppercase {
    font-size: 14px;
    font-family: $inter;
    font-weight: $bold;
}

.font-typography-title-big {
    font-size: 20px !important;
    font-family: $inter !important;
    font-weight: $semiBold !important;
}

.font-typography-title-medium {
    font-size: 24px;
    font-family: $inter;
    font-weight: $bold;
    line-height: 28px;
}

.font-typography-title-page {
    font-family: $inter;
    font-size: 18px;
    font-weight: $medium;
    line-height: 18px;
}

.font-typography-title-small {
    font-size: 15px;
    font-family: $inter;
    font-weight: $medium;
    line-height: 24px;
}

.font-typography-button-label-default {
    font-size: 13px;
    font-family: $inter;
    font-weight: $semiBold;
    line-height: 22px;
}

.font-typography-button-label-big {
    font-size: 24px;
    font-family: $inter;
    font-weight: $bold;
    line-height: 24px;
}

.font-typography-button-label-small {
    font-size: 14px;
    font-family: $inter;
    font-weight: $bold;
    line-height: 24px;
}

.font-typography-text-small-label {
    font-size: 11px;
    font-family: $inter;
    font-weight: $bold;
}

.font-typography-link {
    font-size: 13px;
    font-family: $inter;
    font-weight: $medium;
}

.font-typography-subtitle {
    font-size: 22px;
    font-family: $inter;
    font-weight: $regular;
}

.font-typography-text-hint {
    font-size: 12px;
    font-family: $inter;
    font-weight: $medium;
}

