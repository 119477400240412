@use "../themes";



.mat-badge-content {
    background-color: themes.$color-ui-error-default !important;
    font-weight: themes.$bold;
    font-size: 10px;
}

notifications,
messages,
exports,
support {
    .mat-badge-content, .mat-badge-small .mat-badge-content {
        margin: 0 !important;
        top: -3px !important;
        left: -2px !important;
        font-weight: themes.$black;
    }
}
