@use '../themes';

.mat-mdc-menu-panel:not(.fuse-horizontal-navigation-menu-panel) {
    min-height: 30px !important;
    border-radius: 0 !important;
    box-shadow: 0 2px 1px 1px rgba(0, 0, 0, 0.04) !important;
    -webkit-box-shadow: 0 2px 1px 1px rgba(0, 0, 0, 0.04) !important;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
    -webkit-filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
    //box-shadow: 0 2px 4px 0 rgba(44, 65, 81, 0.3) !important;
    //-webkit-box-shadow: 0 2px 4px 0 rgba(44, 65, 81, 0.3) !important;

    .mat-mdc-menu-content,
    .mat-mdc-menu-content:not(:empty) {
        padding-top: 0;
        padding-bottom: 0;

        background: themes.$color-brand-colors-main-lightest-white;
    }

    &.bottom {
        overflow: visible;
        margin-top: 10px;

        &:before {
            content: '';
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 8px solid themes.$color-brand-colors-main-lightest-white;
            display: inline-block;
            position: absolute;
            top: -8px;
        }
    }

    &.top {
        border-bottom: 1px solid themes.$color-brand-colors-main-lightest-white;
        overflow: visible;
        position: relative;
        top: -20px;

        &:before {
            content: '';
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 8px solid themes.$color-brand-colors-main-lightest-white;
            display: inline-block;
            position: absolute;
            bottom: -8px;
        }
    }

    &.arrow-right {
        &:before {
            right: 10px;
        }

        margin-right: -8px;

        &.fab-button {
            margin-right: 4px;
        }
    }

    &.arrow-center {
        &:before {
            right: calc(50% - 10px);
        }

        margin-right: -8px;

        &.fab-button {
            margin-right: 4px;
        }
    }

    &.arrow-left {
        &:before {
            left: 10px;
        }
    }

    .mat-mdc-menu-item:not(.toolbar-user-trial) {
        color: themes.$color-brand-colors-main-dark;
        border-bottom: 1px solid themes.$color-neutral-lightest;

        &:last-child {
            border-bottom: none;
        }

        &:hover {
            background-color: #ebf6ff !important;
        }

        &:not(.text-error) {
            > .mdc-list-item__primary-text {
                color: themes.$color-brand-colors-main-dark !important;
            }

            > .mat-icon {
                --k-svg-color: #{themes.$color-brand-colors-main-darker};
                color: themes.$color-brand-colors-main-darker;

                svg {
                    color: themes.$color-brand-colors-main-darker;

                    // path {
                    //     stroke: themes.$color-brand-colors-main-darker;
                    // }
                }
            }
        }

        &.text-error {
            > .mdc-list-item__primary-text {
                color: themes.$color-ui-error-default !important;
            }

            > .mat-icon {
                --k-svg-color: #{themes.$color-ui-error-default} !important;
                color: themes.$color-ui-error-default !important;
            }
        }
    }

    &.mat-menu-k-select {
        .mat-mdc-menu-item {
            &.checkbox {
                padding-left: 6px !important;
                padding-top: 4px !important;
                padding-bottom: 4px !important;
            }
        }
    }
}

.user-post {
    .avatar {
        width: 28px;
        height: 28px;
        min-width: 28px;
        min-height: 28px;
        border-radius: 14px;
        border: 2px solid themes.$color-brand-colors-main-default;
        box-shadow: 0 0 0 0 transparent;
    }

    .mat-mdc-menu-item {
        font-size: 15px;
        font-weight: themes.$medium;
        color: themes.$color-brand-colors-main-dark;
    }
}

.mat-mdc-menu-item {
    @apply font-typography-text-medium;
    color: themes.$color-brand-colors-main-dark;

    text-overflow: clip !important;
    line-height: 17px !important;
    height: auto !important;
    padding: 12px 16px !important;
    white-space: normal !important;

    mat-icon {
        $size: 20px;
        font-size: $size;
        width: $size;
        height: $size;
        min-width: $size;
        min-height: $size;
        line-height: $size;
    }

    .mdc-list-item__primary-text, .mat-mdc-menu-item-text {
        font-size: 13px !important;
        line-height: 17px !important;
        font-family: themes.$inter !important;
        font-weight: themes.$medium !important;
    }

    .mat-mdc-checkbox {
        .option-form-mat-menu {
            font-weight: themes.$medium;
            color: themes.$color-brand-colors-main-dark;
        }
    }
}

.filter-menu {
    min-width: 250px !important;

    .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
    .mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
        display: none;
    }

    .mat-input-container {
        margin-bottom: -20px;
    }
}
