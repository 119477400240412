@use "../themes";

.tippy-box[data-theme='kiwid'] {
    background-color: themes.$color-brand-colors-main-lightest-white !important;
    color: themes.$color-brand-colors-main-dark !important;
    box-shadow: 0px 2px 1px 1px rgba(0, 0, 0, 0.04);
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
    font-family: themes.$inter;
    font-weight: themes.$medium;
    font-size: 13px;
    padding: 10px 14px;

    .tippy-arrow {
        color: themes.$color-brand-colors-main-lightest-white !important;
    }

    b, strong {
        font-weight: themes.$semiBold;
    }
}
