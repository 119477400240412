@use '../themes';

/* Font size */
html {
    font-size: 16px;
}

html > * {
    font-size: 13px;
}

body {
    overflow-y: hidden;
    max-height: var(--kiwid-vh-100);

    &:not(.os-mac) ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: rgba(0, 0, 0, 0);
    }
    &:not(.os-mac) ::-webkit-scrollbar-thumb {
        border: 2px solid transparent;
        border-radius: 20px;
        box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.24);
    }
}

[hidden],
.hidden {
    display: none !important;
}

.theme-brand .light .mat-drawer-container,
.theme-brand.light .mat-drawer-container {
    background: themes.$color-neutral-lightest;
}

/* Dialog */
.mat-mdc-dialog-container {
    border-radius: 0 !important;
}

/* Material Overlay */
//.cdk-overlay-backdrop:not(.mat-mdc-overlay-transparent-backdrop):not(.mat-menu-k-select):not(.mat-overlay-transparent-backdrop),
//.fuse-drawer-overlay-fixed {
//    background: rgba(18, 45, 66, 0.8) !important;
//    -webkit-backdrop-filter: blur(1px) !important;
//    backdrop-filter: blur(1px) !important;
//}
.cdk-overlay-backdrop.cdk-overlay-dark-backdrop:not(.mat-mdc-overlay-transparent-backdrop):not(.mat-menu-k-select):not(
        .mat-overlay-transparent-backdrop
    ) {
    background: rgba(18, 45, 66, 0.8) !important;
    -webkit-backdrop-filter: blur(1px) !important;
    backdrop-filter: blur(1px) !important;
}

.k-modal {
    max-width: 100% !important;
    max-height: 100vh !important;

    .mat-mdc-dialog-container {
        padding: 0;
        overflow: hidden;
        border-radius: 0;

        .mat-mdc-dialog-surface {
            padding: 0 !important;
            border-radius: 0 !important;
        }
    }
}

.k-dialog {
    .mat-mdc-dialog-container .mdc-dialog__surface {
        padding: 24px !important;
        border-radius: 4px !important;
    }
}

.cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    opacity: 0.75;

    .hidden-drag {
        display: none !important;
    }
}

.cdk-drag-placeholder {
    opacity: 0;
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

// custom-mat-mdc-menu

.mat-mdc-menu-item {
    min-height: 40px;

    &.mat-icon-options {
        @apply font-typography-text-medium;
        color: themes.$color-brand-colors-main-dark !important;

        min-width: 150px;

        > mat-mdc-icon {
            margin-right: 10px !important;
        }

        &:not(.text-error) {
            > mat-mdc-icon {
                --k-svg-color: #{themes.$color-brand-colors-main-darker} !important;
                color: themes.$color-brand-colors-main-darker !important;

                svg {
                    color: themes.$color-brand-colors-main-darker;

                    path {
                        stroke: themes.$color-brand-colors-main-darker;
                    }
                }
            }
        }

        &.text-error {
            color: themes.$color-ui-error-default !important;

            > mat-mdc-icon {
                --k-svg-color: #{themes.$color-ui-error-default} !important;
                color: themes.$color-ui-error-default !important;
            }
        }
    }
}

.avatar-laboratory {
    .avatar-content {
        border-radius: 4px !important;
    }
}

body .mat-mdc-list-base .mat-mdc-list-item {
    display: table;
}

body {
    .mat-mdc-list-base {
        --mdc-list-list-item-one-line-container-height: auto !important;

        > .mat-mdc-subheader {
            min-height: 40.5px !important;
            padding: {
                top: 9px;
                bottom: 9px;
                left: 24px;
                right: 24px;
            }
            font-style: normal !important;
            font-weight: bold !important;
            font-size: 12px !important;
            line-height: 15px !important;
            letter-spacing: -0.00045px !important;
            color: themes.$color-brand-colors-main-dark;
            border-bottom: 1px solid #dfe4e9;
            margin: 0;
            display: flex;
            align-items: center;
        }
    }

    .mat-mdc-list-item {
        min-height: 20px;
        border-bottom: 1px solid #dfe4e9;
        display: flex !important;
        justify-content: start;
        align-items: center;
        padding: 4px 24px;

        .mat-mdc-list-item-unscoped-content {
            display: block !important;
        }

        .mdc-list-item__content {
            pointer-events: all;
            height: 31px;
        }

        &.hidden,
        &[hidden] {
            display: none !important;
        }

        &:hover {
            &.disabled-hover {
                background: #ffffff;
            }
        }

        .menu-title {
            width: 100%;
            min-height: 30px;
            font-family: Inter, serif;
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            letter-spacing: -0.000525px;
            color: themes.$color-brand-colors-main-darkest;
        }
    }

    .mdc-list {
        padding: 0;

        &.multiple {
            .mat-mdc-list-item {
                padding: 10px 24px;
            }
        }
    }
}

.mat-mdc-list-base .mat-mdc-list-item .mat-mdc-list-item-content,
.mat-mdc-list-base .mat-mdc-list-option .mat-mdc-list-item-content {
    height: auto !important;
    padding: 0 30px !important;
}

// custom select mat-mdc-menu
.k-select-mat-mdc-menu-item {
    min-height: 42px;
}

.select-title-mat-mdc-menu {
    font-size: 13px !important;
    font-family: themes.$inter !important;
    font-weight: themes.$medium !important;
    color: themes.$color-brand-colors-main-darker !important;
}

.border-bottom-mat-mdc-menu {
    border-bottom: 1px solid #f1f4f5 !important;
}

.mat-input-with-select {
    position: relative;

    .mdc-button__label {
        display: flex;
        align-items: center;

        svg {
            margin-top: -3px;
        }
    }

    .mat-form-field-appearance-outline .mat-mdc-form-field-icon-prefix,
    .mat-form-field-appearance-outline .mat-mdc-form-field-icon-suffix {
        top: 0 !important;
        min-width: 45px !important;
    }

    .disable-padding-error {
        .mat-mdc-form-field-wrapper {
            padding-bottom: 0 !important;
            margin-bottom: 0 !important;
        }
    }

    .mat-select-phone {
        width: 45px;
        height: 0;
        max-height: 0;
        min-height: 0;
        bottom: 0;
        left: 0;
    }

    .mat-select-price {
        width: 45px;
        height: 0;
        max-height: 0;
        min-height: 0;
        bottom: 0;
        left: 0;
    }

    .mat-price-button {
        position: relative;

        span {
            min-height: 18px !important;
            font-family: Inter, serif !important;
            font-style: normal !important;
            font-weight: bold !important;
            font-size: 13px !important;
            line-height: 150% !important;
            color: themes.$color-neutral-darker !important;
        }
    }
}

mat-optgroup.select-group-stick {
    $paddingX: 16px !important;

    .mat-mdc-optgroup-label,
    .mat-mdc-optgroup-label .mdc-list-item__primary-text {
        position: sticky;
        z-index: 1; // appear above mat-mdc-option
        top: 0; // fix at the top
        background-color: themes.$color-brand-colors-main-lightest;
        color: themes.$color-brand-colors-main-darker;
        font-weight: themes.$semiBold;
        font-size: 11px;
        line-height: 1.3;
        text-transform: uppercase;

        // padding-left: $paddingX;
        // padding-right: $paddingX;
    }

    &.enabled-search {
        .mat-mdc-optgroup-label {
            top: 55px;
        }
    }

    .mat-mdc-option {
        padding-left: $paddingX;
        padding-right: $paddingX;
    }
}

.mat-mdc-menu-employee {
    max-width: none !important;
    margin-right: 10px !important;
    margin-left: 10px !important;

    .mat-mdc-menu-content {
        min-width: 400px !important;
    }

    @media screen and (max-width: 420px) {
        .mat-mdc-menu-content {
            min-width: calc(100vw - 20px) !important;
        }
    }
}

.mat-mdc-menu-k-select {
    .mat-mdc-menu-content {
        max-height: 400px !important;
        overflow: auto;
    }
}

.kw-stars-unit {
    .start-item {
        display: table;
        position: relative;

        > span {
            float: left;
            height: 24px;

            mat-mdc-icon {
                $size: 24px;
                font-size: $size;
                width: $size;
                height: $size;
                min-width: $size;
                min-height: $size;
            }
        }

        .star-inactive {
            position: absolute;
            top: 0;
            left: 0;
            -webkit-text-stroke: initial;
            mat-icon {
                color: themes.$color-neutral-light;
            }
        }

        .star-active {
            position: relative;
            z-index: 1;
            display: inline-block;
            overflow: hidden;
            mat-icon {
                color: themes.$color-ui-warning-default;
            }
        }
    }
}

.kw-ellipse-container {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .kw-ellipse-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.small-font {
    font-family: Inter, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.000525px;
    text-decoration: none !important;

    &:hover {
        text-decoration: none !important;
    }

    &.dark {
        color: themes.$color-neutral-darker !important;
    }
}

.no-break-line {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: block !important;
}

.mat-form-field-checkbox {
    margin: 0.25em 0 !important;
    padding-bottom: 0.8em !important;
    height: 58px !important;
    display: flex !important;
    align-items: center !important;
}

.mat-form-field-slide {
    display: flex !important;
    align-items: center !important;
    margin: 0 0 20px 0 !important;
    height: 42px !important;
    padding-bottom: 0.8em !important;
}

.mat-mdc-form-field-layout {
    margin: 0.25em 0 !important;
    margin-bottom: calc(0.25em + 5px) !important;
    padding-bottom: 1.34375em !important;
    height: 47.41px !important;
    display: flex !important;
    align-items: center !important;
}

// toastr
.toastr {
    @apply font-typography-text-small;

    border-radius: 4px;

    padding: 8px 16px;
    margin: 0;

    &.toastr-neutral {
        background-color: themes.$color-neutral-lightest;
        color: themes.$color-neutral-lighter;
    }

    &.toastr-error {
        background-color: themes.$color-ui-error-lighter;
        color: white;
    }
}

// icons
.ic-settings-style {
    svg {
        ::ng-deep {
            & > path,
            g > path {
                stroke: themes.$color-neutral-lighter !important;
            }
        }
    }
}

.k-badge {
    background: themes.$color-ui-error-default;
    padding: 2px 7px;
    min-width: 19px;
    height: 18px;
    border-radius: 41px;
    font-weight: themes.$bold;
    size: 10px;
    color: themes.$color-neutral-white;
}

.mat-mdc-option {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: auto !important;
    width: 100% !important;
    min-height: 40px !important;
}

.mat-mdc-option-text {
    text-wrap: normal !important;
    word-wrap: break-word !important;
    white-space: break-spaces !important;
    text-overflow: unset !important;
    line-height: 20px !important;
    margin: 10px 0 !important;
}

.mat-mdc-select-min-line {
    max-width: 100%;
    display: inline-block;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.kd-steps-horizontal {
    display: flex;
    counter-reset: step;
    padding: 0;
    margin: 0;

    > li {
        width: 100%;
        list-style: none;
        display: inline-block;
        position: relative;
        text-align: center;
        cursor: pointer;

        > span {
            display: table;
            max-width: 93px;
            margin: 0 auto;

            color: themes.$color-neutral-darker;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;

            > span.required {
                color: themes.$color-ui-error-default;
            }
        }

        &.disabled {
            cursor: default !important;
        }

        &.disabled,
        &.done {
            > span {
                display: table;
                max-width: 93px;
                margin: 0 auto;

                color: themes.$color-neutral-lighter;
                font-style: normal;
                font-weight: 500;
                font-size: 13px;

                > span.required {
                    color: themes.$color-neutral-lighter;
                }
            }
        }

        // before
        &:before {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            content: counter(step);
            counter-increment: step;
            width: 24px;
            height: 24px;
            margin: auto auto 10px auto;
            z-index: 1;
            font-size: 13px;
            font-weight: 900;
            text-align: center;
            border-radius: 100%;
            background-color: themes.$color-neutral-white;
            border: 2px solid themes.$color-neutral-lightest;
            color: themes.$color-neutral-light;
            transition: all 250ms ease-out;
        }

        &.disabled:before {
            background-color: themes.$color-neutral-light;
            border-color: themes.$color-neutral-light;
            color: themes.$color-neutral-default;
        }

        &.active:before {
            background-color: white;
            border-color: themes.$color-brand-colors-main-darker;
            color: themes.$color-brand-colors-main-darker;
        }

        // after
        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 4px;
            background-color: #e2e8ea;
            top: 10px;
            left: -50%;
            z-index: 0;
        }

        &.done::before {
            background-color: themes.$color-ui-success-default;
            border-color: themes.$color-ui-success-default;
            color: themes.$color-ui-success-default;
            content: url('/assets/images/onboarding/check.svg');
        }

        &:first-child:after {
            content: none;
        }

        &.active:after {
            background: linear-gradient(to right, themes.$color-ui-success-default, themes.$color-brand-colors-main-darker) !important;
        }

        &.done:after {
            background-color: themes.$color-ui-success-default !important;
        }
    }
}

.k-ellipse {
    content: '';
    width: 12px !important;
    height: 12px !important;
    border-radius: 25px;
    background: themes.$color-neutral-default;

    &.size-8 {
        width: 8px !important;
        height: 8px !important;
    }

    &.size-6 {
        width: 8px !important;
        height: 8px !important;
    }

    &.main-darker {
        background: themes.$color-brand-colors-main-darker;
    }

    &.success-darker {
        background: themes.$color-ui-success-darker;
    }

    &.success-default {
        background: themes.$color-ui-success-default;
    }

    &.error-default {
        background: themes.$color-ui-error-default;
    }
}

.mat-mdc-date-range-close {
    cursor: pointer !important;

    position: absolute !important;
    top: 4px !important;
    right: -3px !important;

    color: themes.$color-brand-colors-main-darker !important;

    $size: 16px;
    font-size: $size !important;
    width: $size !important;
    height: $size !important;
    min-width: $size !important;
    min-height: $size !important;
    line-height: $size !important;
}

// colors
.color-neutral-lighter {
    color: themes.$color-neutral-lighter;
}

.color-neutral-default {
    color: themes.$color-neutral-default;
}

.color-main-darker {
    color: themes.$color-brand-colors-main-darker;
}

.color-success-default {
    color: themes.$color-ui-success-default;
}

.color-error-default {
    color: themes.$color-ui-error-default;
}

// colors mat-icon
.mat-icon-color-white {
    --k-svg-color: white;
    color: white;
}

.mat-icon-color-error-default {
    --k-svg-color: #{themes.$color-ui-error-default};
    color: themes.$color-ui-error-default;
}

.mat-icon-color-neutral-lighter {
    --k-svg-color: #{themes.$color-neutral-lighter};
    color: themes.$color-neutral-lighter;
}

.mat-icon-color-neutral-default {
    --k-svg-color: #{themes.$color-neutral-default};
    color: themes.$color-neutral-default;
}

.mat-icon-color-neutral-darker {
    --k-svg-color: #{themes.$color-neutral-darker};
    color: themes.$color-neutral-darker;
}

.mat-icon-color-brand-colors-main-darker {
    --k-svg-color: #{themes.$color-brand-colors-main-darker};
    color: themes.$color-brand-colors-main-darker;
}

.mat-icon-color-brand-colors-main-dark {
    --k-svg-color: #{themes.$color-brand-colors-main-dark};
    color: themes.$color-brand-colors-main-dark;
}

// margins
.mr-2 {
    margin-right: 2px;
}

.ml-2 {
    margin-right: 2px;
}

::ng-deep .mat-mdc-chip {
    background-color: themes.$color-neutral-lightest !important;
    color: themes.$color-neutral-darker;

    font-weight: themes.$bold;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 0;

    button {
        outline: none;
    }
}

.mat-mdc-icon-button:hover .mat-mdc-button-focus-overlay {
    opacity: 0.05 !important;
}

fuse-drawer {
    -webkit-overflow-scrolling: touch;
}

// loader
.loader {
    ::ng-deep circle {
        stroke: themes.$color-brand-colors-main-darker !important;
    }
}

.loader-lightest {
    ::ng-deep circle {
        stroke: themes.$color-brand-colors-main-lightest !important;
    }
}

.footer-loader {
    width: 100%;

    .loader {
        margin: 0 auto;
    }
}

.select-title-mat-menu {
    font-size: 13px !important;
    font-family: Inter, sans-serif !important;
    font-weight: 500 !important;
    color: themes.$color-brand-colors-main-darker !important;
}

.mat-mdc-button {
    .mdc-button__label {
        width: 100%;
    }
}

loading {
    @apply w-full h-full flex flex-row justify-center items-center;
}

.k-select-search {
    padding: 0 16px !important;
    background: white !important;
    opacity: 1 !important;

    &.sticky {
        position: sticky;
        z-index: 1; // appear above mat-option
        top: 0; // fix at the top
    }

    > span {
        min-height: 54px !important;
        height: 54px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        k-form-input {
            width: 100%;
            margin: auto;
        }
    }
}

button.k-button-select {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    border-radius: 4px;
    min-height: 34px;
    // font
    font-family: themes.$inter !important;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    color: themes.$color-brand-colors-main-dark !important;
    padding: 6px 10px;

    > mat-icon {
        color: themes.$color-brand-colors-main-dark !important;
        display: flex;
        justify-content: center;
        justify-items: center;
        align-content: center;
        align-items: center;
        margin-left: 2px;
    }

    &.disabled {
        background-color: themes.$color-neutral-lightest !important;
        color: themes.$color-neutral-light !important;
    }
}

.k-form-date-timepicker-mat-menu {
    width: 296px !important;
    min-width: 296px !important;
}

.mat-mdc-form-field-hint {
    &.success-darker {
        color: themes.$color-ui-success-darker !important;
    }

    &.success-default {
        color: themes.$color-ui-success-default !important;
    }
}

.k-scroll {
    overflow: auto;
    mask-image: linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 8px, black 8px);
    mask-size: 100% 20000px;
    -webkit-mask-image: linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 8px, black 8px);
    -webkit-mask-size: 100% 20000px;
    -webkit-mask-position: left bottom;
    transition:
        mask-position 0.3s,
        -webkit-mask-position 0.3s;

    &:hover,
    &:focus {
        -webkit-mask-position: left top;
    }
}

.k-scroll-view {
    $size: 200px;
    position: absolute;
    z-index: 90 !important;
    margin-top: -$size !important;
    width: 0 !important;
    height: 0 !important;
    background-color: red;
}

// .mat-mdc-form-field-hint-wrapper {
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;

//     .mat-mdc-form-field-hint-spacer {
//         display: none !important;
//     }
// }

.label-remake {
    width: 18px;
    height: 18px;
    text-align: center;
    line-height: 18px;
    color: white;
    background: themes.$color-ui-error-default;
    border-radius: 9px;
    font-weight: themes.$bold;
    font-size: 10px;
}

input[type='number'] {
    &:not(.mat-mdc-input-element),
    &.text-center {
        -moz-appearance: textfield !important;

        &::-webkit-inner-spin-button {
            -webkit-appearance: none !important;
            margin: 0 !important;
        }
        &::-webkit-outer-spin-button {
            -webkit-appearance: none !important;
            margin: 0 !important;
        }
    }
}

mat-form-field[appearance='fill'] {
    input[type='number'] {
        -moz-appearance: textfield !important;

        &::-webkit-inner-spin-button {
            -webkit-appearance: none !important;
            margin: 0 !important;
        }
        &::-webkit-outer-spin-button {
            -webkit-appearance: none !important;
            margin: 0 !important;
        }
    }
}