@use '../themes';

button {
    &.btn-delete {
        border: none;
        border-radius: 8px;
        background: themes.$color-neutral-lightest;
        color: themes.$color-neutral-default;
        width: 36px;
        height: 36px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        &:hover {
            background: themes.$color-neutral-light;
            color: themes.$color-neutral-darker;
        }
    }
}

button.mdc-fab {
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 1;
}

.mdc-button:hover:not(.mat-mdc-button-disabled) .mat-mdc-button-focus-overlay,
.mat-mdc-stroked-button:hover:not(.mat-mdc-button-disabled) .mat-mdc-button-focus-overlay {
    opacity: 0.04 !important;
}

.mat-mdc-button,
.mat-mdc-flat-button,
.mat-mdc-raised-button,
.mat-mdc-stroked-button {
    border-radius: 4px !important;
    padding: 6px 10px !important;
}

.mat-mdc-fab.mat-primary {
    --mdc-fab-container-color: themes.$color-brand-colors-main-darker;
    background-color: themes.$color-brand-colors-main-darker;

    mat-icon {
        color: white;
        --k-svg-color: white;
    }
}


.mat-mdc-fab {
    mat-icon.menu-opened {
        transform: rotate(45deg);
    }
}
