@use "../themes";

/* -------------------------------------------------------------------------- */
/*  @ Horizontal
/* -------------------------------------------------------------------------- */
.fuse-horizontal-navigation-item-icon {
    width: 20px #{'#{'!important'}'};
    min-width: 20px #{'#{'!important'}'};
    height: 20px #{'#{'!important'}'};
    min-height: 20px #{'#{'!important'}'};
    font-size: 20px #{'#{'!important'}'};
    line-height: 20px #{'#{'!important'}'};

    --k-svg-color:  #{themes.$color-brand-colors-main-darker '!important'};
}

/* Basic, Branch */
.fuse-horizontal-navigation-menu-active {
    .fuse-horizontal-navigation-item-wrapper {
        background-color: rgba(0,0,0,.04) #{'#{'!important'}'};

        .fuse-horizontal-navigation-item-title,
        .fuse-horizontal-navigation-item-icon {
            color: white !important;
            --k-svg-color: white !important;
        }
    }
}

.fuse-horizontal-navigation-item-wrapper, {
    border-radius: 0 #{'#{'!important'}'};

    &:hover {
        ::ng-deep {
            background-color: rgba(0,0,0,.04) #{'#{'!important'}'};
        }

        .fuse-horizontal-navigation-item-title,
        .fuse-horizontal-navigation-item-icon {
            color: white #{'#{'!important'}'};
            --k-svg-color: white #{'!important'};
        }
    }
}

.fuse-horizontal-navigation-item-active,
.fuse-horizontal-navigation-item {
    height: 52px;
    border-bottom: 3px solid themes.$color-brand-colors-main-darker;
    padding: 0 20px #{'#{'!important'}'};
}

.fuse-horizontal-navigation-item-title {
    font-size: 14px;
    font-weight: themes.$semiBold;
}

.fuse-horizontal-navigation-item-title,
.fuse-horizontal-navigation-item-icon {
    color: themes.$color-brand-colors-main-light #{'#{'!important'}'};
    --k-svg-color:  #{themes.$color-brand-colors-main-light '!important'};
}

.fuse-horizontal-navigation-item-active {
    border-color: white;

    .fuse-horizontal-navigation-item-title,
    .fuse-horizontal-navigation-item-icon {
        color: white  #{'#{'!important'}'};
    }
}


.fuse-horizontal-navigation-menu-panel {
    min-height: auto;
    border-radius: 0px #{'!important'};
    box-shadow: 0px 2px 1px 1px rgba(0, 0, 0, 0.04) #{'!important'};
    -webkit-box-shadow: 0px 2px 1px 1px rgba(0, 0, 0, 0.04) #{'!important'};
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
    -webkit-filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
    background: themes.$color-brand-colors-main-darker;

    .mat-mdc-menu-content,
    .mat-mdc-menu-content:not(:empty) {
        padding-top: 0;
        padding-bottom: 0;

        background: themes.$color-brand-colors-main-darker;
    }

    .fuse-horizontal-navigation-item-active, .fuse-horizontal-navigation-item {
        border-bottom: 0 #{'!important'};
        height: 45px;
    }

    .mat-menu-item {
        color: white #{'!important'};
        border-bottom: 0 #{'!important'};

        .fuse-horizontal-navigation-item-title {
            color: white #{'!important'};
        }

        .fuse-horizontal-navigation-item-active {
            background-color: rgba(0,0,0,.1) #{'!important'};

            .fuse-horizontal-navigation-item-title {
                color: white #{'!important'};
            }
        }

        &:hover {
            background-color: transparent #{'!important'};
        }

        .mat-icon {
            color: themes.$color-brand-colors-main-darker #{'!important'};
            --k-svg-color:  #{themes.$color-brand-colors-main-darker '!important'};
        }
    }

    .fuse-horizontal-navigation-menu-item {
        padding: 0 #{'#{'!important'}'};
        border: 0 #{'#{'!important'}'};
        height: 48px;

        .mat-ripple {
            display: none;
        }
    }
}

.toolbar-icons {
    mat-icon {
        color: themes.$color-brand-colors-main-dark #{'!important'};
        --k-svg-color:  #{themes.$color-brand-colors-main-dark '!important'};
    }
}

/* Mobile */
.fuse-vertical-navigation-content-header {
    background: white;
}

/* -------------------------------------------------------------------------- */
/*  @ Vertical
/* -------------------------------------------------------------------------- */

.fuse-vertical-navigation-item-icon {
    width: 20px #{'#{'!important'}'};
    min-width: 20px #{'#{'!important'}'};
    height: 20px #{'#{'!important'}'};
    min-height: 20px #{'#{'!important'}'};
    font-size: 20px #{'#{'!important'}'};
    line-height: 20px #{'#{'!important'}'};

    --k-svg-color:  #{white '!important'};
}

/* Basic, Branch */
.fuse-vertical-navigation-menu-active {
    .fuse-vertical-navigation-item-wrapper {
        background-color: rgba(0,0,0,.04) #{'#{'!important'}'};

        .fuse-vertical-navigation-item-title,
        .fuse-vertical-navigation-item-icon {
            color: white #{'#{'!important'}'};
            --k-svg-color: white #{'!important'};
        }
    }
}


.fuse-vertical-navigation-item-title {
    font-size: 14px;
    font-weight: themes.$semiBold;
}

.fuse-vertical-navigation-item-title,
.fuse-vertical-navigation-item-icon {
    color: themes.$color-brand-colors-main-light #{'#{'!important'}'};
    --k-svg-color:  #{themes.$color-brand-colors-main-light '!important'};
}

.fuse-vertical-navigation-item-active,
.fuse-vertical-navigation-item-expanded {
    .fuse-vertical-navigation-item-title,
    .fuse-vertical-navigation-item-icon {
        color: white  #{'!important'};
        --k-svg-color:  #{white '!important'};
    }
}


//.fuse-vertical-navigation-menu-panel {
//    min-height: auto;
//    border-radius: 0px #{'!important'};
//    box-shadow: 0px 2px 1px 1px rgba(0, 0, 0, 0.04) #{'!important'};
//    -webkit-box-shadow: 0px 2px 1px 1px rgba(0, 0, 0, 0.04) #{'!important'};
//    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
//    -webkit-filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
//    background: themes.$color-brand-colors-main-darker;
//
//    .mat-mdc-menu-content,
//    .mat-mdc-menu-content:not(:empty) {
//        padding-top: 0;
//        padding-bottom: 0;
//
//        background: themes.$color-brand-colors-main-darker;
//    }
//
//    .fuse-horizontal-navigation-item-active, .fuse-horizontal-navigation-item {
//        border-bottom: 0 #{'!important'};
//        height: 45px;
//    }
//
//    .mat-menu-item {
//        color: white #{'!important'};
//        border-bottom: 0 #{'!important'};
//
//        .fuse-horizontal-navigation-item-title {
//            color: white #{'!important'};
//        }
//
//        .fuse-horizontal-navigation-item-active {
//            background-color: rgba(0,0,0,.1) #{'!important'};
//
//            .fuse-horizontal-navigation-item-title {
//                color: white #{'!important'};
//            }
//        }
//
//        &:hover {
//            background-color: transparent #{'!important'};
//        }
//
//        .mat-icon {
//            color: themes.$color-brand-colors-main-darker #{'!important'};
//            --k-svg-color:  #{themes.$color-brand-colors-main-darker '!important'};
//        }
//    }
//
//    .fuse-horizontal-navigation-menu-item {
//        padding: 0 #{'#{'!important'}'};
//        border: 0 #{'#{'!important'}'};
//        height: 48px;
//
//        .mat-ripple {
//            display: none;
//        }
//    }
//}
