@use "../themes";

.mat-expansion-panel {
    border: none !important;
    box-sizing: border-box !important;
    @apply font-typography-text-small;
    color: themes.$color-neutral-darkest !important;
    margin: 0;
    box-shadow: 0 2px 1px 1px rgba(0, 0, 0, 0.04), 0 1px 8px rgba(0, 0, 0, 0.08) !important;
    background: white !important;

    .mat-expansion-panel-header {
        min-height: 64px;
        height: 64px;
    }

    .mat-expansion-panel-body,
    .mat-expansion-panel-header {
        padding: 0 !important;
    }

    .mat-expansion-panel-header-description {
        margin: 0 !important;

        > mat-mdc-icon {
            color: themes.$color-neutral-dark !important;
        }
    }

    .mat-expansion-panel-header {
        padding: 16px !important;
        min-height: 60px !important;
        height: auto !important;
    }
}

.mat-accordion .mat-expansion-panel:first-of-type {
    //border-bottom-right-radius: 0px !important;
    //border-bottom-left-radius: 0px !important;
    //border-radius: 0px !important;
}


.mat-accordion {

    .mat-expansion-panel {
        margin-bottom: 24px;
        border-radius: 0px !important;
        background-color: white;
        transition: box-shadow 225ms cubic-bezier(0.4, 0.0, 0.2, 1);
        box-shadow: 0 2px 1px 1px rgba(0, 0, 0, 0.04), 0 1px 8px rgba(0, 0, 0, 0.08) !important;

        &:last-child {
            margin-bottom: 0;
        }

        &.mat-expanded,
        &:hover {
            box-shadow: 0 2px 1px 1px rgba(0, 0, 0, 0.04), 0 1px 8px rgba(0, 0, 0, 0.08) !important;
        }

        &:not(.mat-expanded) {

            .mat-expansion-panel-header {

                &:not([aria-disabled=true]) {

                    &.cdk-keyboard-focused,
                    &.cdk-program-focused,
                    &:hover {
                        //background: transparent !important;
                    }
                }
            }
        }

        .mat-content-hide-toggle {
            margin-left: 0 !important;
        }

        .mat-expansion-panel-header {
            font-size: 13px;

            &[aria-disabled=true] {

                .mat-expansion-panel-header-description {
                    margin-right: 28px;
                }
            }

            .mat-expansion-indicator {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 12px;
                height: 12px;

                /* Do not override the border color of the expansion panel indicator */
                &:after {
                    //border-color: currentColor !important;
                }
            }
        }

        .mat-expansion-panel-body {
            @apply text-secondary #{'!important'};
        }
    }
}
