@use '../../../core/styles/overrides/breakpoints';

i,
mat-icon {
    $size: 24px;
    font-size: $size  !important;
    width: $size  !important;
    height: $size  !important;
    min-width: $size  !important;
    min-height: $size  !important;
    line-height: $size  !important;

    &.size-12 {
        $size: 12px;
        font-size: $size  !important;
        width: $size  !important;
        height: $size  !important;
        min-width: $size  !important;
        min-height: $size  !important;
        line-height: $size  !important;
    }

    &.size-14 {
        $size: 14px;
        font-size: $size  !important;
        width: $size  !important;
        height: $size  !important;
        min-width: $size  !important;
        min-height: $size  !important;
        line-height: $size  !important;
    }

    &.size-16 {
        $size: 16px;
        font-size: $size  !important;
        width: $size  !important;
        height: $size  !important;
        min-width: $size  !important;
        min-height: $size  !important;
        line-height: $size  !important;
    }

    &.size-18 {
        $size: 18px;
        font-size: $size  !important;
        width: $size  !important;
        height: $size  !important;
        min-width: $size  !important;
        min-height: $size  !important;
        line-height: $size  !important;
    }

    &.size-20 {
        $size: 20px;
        font-size: $size  !important;
        width: $size  !important;
        height: $size  !important;
        min-width: $size  !important;
        min-height: $size  !important;
        line-height: $size  !important;
    }

    &.size-22 {
        $size: 22px;
        font-size: $size  !important;
        width: $size  !important;
        height: $size  !important;
        min-width: $size  !important;
        min-height: $size  !important;
        line-height: $size  !important;
    }

    &.size-24 {
        $size: 24px;
        font-size: $size  !important;
        width: $size  !important;
        height: $size  !important;
        min-width: $size  !important;
        min-height: $size  !important;
        line-height: $size  !important;
    }

    &.size-28 {
        $size: 28px;
        font-size: $size  !important;
        width: $size  !important;
        height: $size  !important;
        min-width: $size  !important;
        min-height: $size  !important;
        line-height: $size  !important;
    }

    &.size-30 {
        $size: 30px;
        font-size: $size  !important;
        width: $size  !important;
        height: $size  !important;
        min-width: $size  !important;
        min-height: $size  !important;
        line-height: $size  !important;
    }

    &.size-32 {
        $size: 32px;
        font-size: $size  !important;
        width: $size  !important;
        height: $size  !important;
        min-width: $size  !important;
        min-height: $size  !important;
        line-height: $size  !important;
    }

    &.size-43 {
        $size: 43px;
        font-size: $size  !important;
        width: $size  !important;
        height: $size  !important;
        min-width: $size  !important;
        min-height: $size  !important;
        line-height: $size  !important;
    }

    @each $breakpoint, $materialBreakpoint in breakpoints.$helper-breakpoints {
        @include breakpoints.media-breakpoint($materialBreakpoint) {
            $infix: if($materialBreakpoint == null, '', '-#{$breakpoint}');

            @for $size from 2 through 128 {
                &.s#{$infix}-#{$size * 2} {
                    font-size: #{($size * 2) + 'px'} !important;
                    width: #{($size * 2) + 'px'} !important;
                    height: #{($size * 2) + 'px'} !important;
                    min-width: #{($size * 2) + 'px'} !important;
                    min-height: #{($size * 2) + 'px'} !important;
                    line-height: #{($size * 2) + 'px'} !important;
                }
            }
        }
    }
}
