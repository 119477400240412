@use '../themes';
/* This injects Tailwind's base styles and any base styles registered by plugins. */
* {
    --mdc-shape-small: 8px;
}


button {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    outline: none;
}

input[type='email'],
input[type='tel'],
input[type='text'],
input[type='password'],
input[type='image'],
input[type='submit'],
input[type='button'],
input[type='search'],
input,
textarea {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    outline: none;
    font-size: 13px #{'!important'};
    color: themes.$color-neutral-dark;
}

/* -------------------------------------------------------------------------- */
/*  @ Font smoothing
/* -------------------------------------------------------------------------- */
*[class*=mat-],
*[class*=mat-mdc-] {
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
    -ms-font-smoothing: antialiased;
    font-smoothing: antialiased;

    * {
        -webkit-font-smoothing: antialiased;
        -moz-font-smoothing: antialiased;
        -o-font-smoothing: antialiased;
        -ms-font-smoothing: antialiased;
        font-smoothing: antialiased;
    }
}

.mdc-text-field--outlined:not(.mdc-text-field) {
    height: 38px !important;
}

.mat-mdc-form-field-infix {
    min-height: 30px !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    --mat-mdc-form-field-label-transform: translateY(-1.95em) scale(0.75) !important;
}

.mat-mdc-input-element {
    line-height: 1.2;
}

.mdc-text-field--outlined {
    padding-left: max(10px, var(--mdc-shape-small, 4px)) !important;
    padding-right: max(10px, var(--mdc-shape-small, 4px)) !important;
}

.mat-mdc-form-field-icon-suffix {
    height: 24px;
}

.mat-mdc-form-field-icon-prefix > .mat-icon, .mat-mdc-form-field-icon-suffix > .mat-icon {
    padding: 0 !important;
    color: themes.$color-neutral-default !important;
}


.mdc-floating-label {
    font-size: 13px;
    line-height: 1.2;
    color: themes.$color-neutral-default !important;
}

.mdc-floating-label--float-above {
    font-weight: themes.$bold !important;
    color: themes.$color-neutral-darker !important;
}


.mat-form-field-appearance-outline .mdc-floating-label {
    top: 1.4em #{'!important'};
    margin-top: 0 #{'!important'};
}

.mat-mdc-form-field-should-float {
    .mdc-floating-label {
        color: themes.$color-neutral-darker !important;
    }
}

.mat-form-field-outline {
    color: themes.$color-neutral-light !important;
}

.mat-mdc-form-field-type-mat-mdc-native-select .mat-mdc-form-field-infix::after {
    color: themes.$color-neutral-dark !important;
}

.mat-form-field-appearance-outline {
    margin-bottom: 0 !important;

    .mat-mdc-icon {
        color: themes.$color-neutral-default !important;
    }

    &.mat-form-field-disabled:not(.readonly) {
        .mat-mdc-icon {
            color: themes.$color-neutral-darker !important;
        }
    }
}

.mat-mdc-form-field.mat-form-field-disabled.readonly {
    .mat-mdc-input-element:disabled,
    .mat-mdc-select-value-text,
    .mat-mdc-form-field-type-mat-mdc-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
        color: themes.$color-neutral-darker !important;
    }

    .mat-mdc-icon {
        color: themes.$color-neutral-default !important;
    }

    .mat-form-field-outline {
        color: themes.$color-neutral-light !important;
    }

    .mdc-floating-label {
        color: themes.$color-neutral-default !important;
    }

    &.mat-mdc-form-field-should-float {
        .mdc-floating-label {
            color: themes.$color-neutral-darker !important;
        }
    }
}


.mat-mdc-form-field.mat-form-field-disabled:not(.readonly) {
    .mat-mdc-input-element:disabled,
    .mat-mdc-select-value-text,
    .mat-mdc-form-field-type-mat-mdc-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
        color: themes.$color-neutral-darker !important;
    }

    .mat-form-field-outline {
        color: #E8E8E8 !important;
        background-color: themes.$color-neutral-lightest !important;
        border-radius: 8px !important;
    }

    &.mat-mdc-form-field-should-float {
        .mdc-floating-label {
            color: themes.$color-neutral-lighter !important;
        }
    }
}

//.mat-mdc-form-field-error-wrapper, .mat-mdc-form-field-subscript-wrapper {
//    margin-top: 0.2em;
//    font-size: 85%;
//    padding-bottom: 5px;
//}

//.mat-mdc-form-field-error-wrapper, .mat-mdc-form-field-subscript-wrapper {
.mat-mdc-form-field-hint, .mat-mdc-form-field-subscript-wrapper {
    margin-top: 0.2em;
    font-size: 80%;
    padding-bottom: 5px;
}

.mat-mdc-form-field-hint, .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-subscript-dynamic-size {
    margin: 0;
    font-size: 80%;
    padding: 0;
}

.mat-mdc-form-field-hint, .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-subscript-dynamic-size mat-error {
    margin-top: 0.4em;
}

.mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
    padding: 0 10px !important;
}

.mat-mdc-form-field-bottom-align::before {
    height: 6px !important;
}

mat-error {
    color: themes.$color-ui-error-default !important;
}

.mat-form-field-appearance-outline {
    margin-bottom: 5px;

    input {
        padding: 0;
    }

    &.mat-form-field-invalid {
        &.mat-form-field-invalid {
            .mat-form-field-outline-thick,
            .mat-mdc-form-field-error {
                color: themes.$color-ui-error-default !important;
            }

            .mdc-floating-label {
                mat-mdc-label,
                .mat-mdc-placeholder-required {
                    color: themes.$color-ui-error-default !important;
                }
            }
        }
    }

    &:hover {
        .mat-form-field-outline-thick {
            color: themes.$color-brand-colors-main-darker !important;
        }
    }

    &.mat-mdc-focused {
        .mat-form-field-outline-thick {
            color: themes.$color-brand-colors-main-darker !important;
        }

        .mdc-floating-label {
            mat-mdc-label,
            .mat-mdc-placeholder-required {
                color: themes.$color-brand-colors-main-darker !important;
            }
        }
    }
}

.mat-form-field-appearance-outline {
    &.mat-mdc-form-field-can-float {
        &.mat-mdc-form-field-should-float {
            .mdc-floating-label {
                font-weight: themes.$bold !important;
                line-height: 1.2 !important;
            }
        }
    }
}

.mat-form-field-appearance-outline {
    .mat-mdc-form-field-infix {
        padding-top: 9px !important;
        padding-bottom: 9px !important;
        font-size: 13px;
        z-index: 2;
    }
}

.mdc-floating-label-wrapper {
    top: -1.6em !important;
}

.mat-mdc-option-text {
    color: themes.$color-neutral-dark !important;
}

.mat-mdc-option-disabled {
    .mat-mdc-option-text {
        color: themes.$color-neutral-darker !important;
    }
}

//RADIO BUTTON
.mat-mdc-radio-outer-circle {
    border-color: themes.$color-neutral-dark;
    border-width: 0.14rem !important;
}

.mat-mdc-radio-button.mat-mdc-accent .mat-mdc-radio-inner-circle {
    background-color: themes.$color-brand-colors-main-darker;
}

.mat-mdc-radio-button.mat-mdc-accent.mat-mdc-radio-checked .mat-mdc-radio-outer-circle {
    border-color: themes.$color-brand-colors-main-darker;
}

// SELECT
.mat-mdc-select {
    font-size: 13px !important;
}

.mat-mdc-select-arrow {
    font-family: 'Material Icons', serif !important;
    font-weight: normal;
    font-style: normal;
    font-size: 18px;
    // line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    color: themes.$color-neutral-dark !important;

    border: none !important;
    width: 18px !important;
    height: 18px !important;
    padding: 0;
    margin: -4px 0 0 !important;

    &:before {
        content: 'keyboard_arrow_down';
    }
}

.mat-mdc-option {
    border-bottom: 1px solid #dfe4e9;

    font-size: 13px #{'!important'};
    color: themes.$color-neutral-darker;

    &.mat-mdc-active,
    &.mat-mdc-selected:not(.mat-mdc-option-multiple) {
        background: white !important;
        color: themes.$color-brand-colors-main-darker !important;
    }

    &:not(.search):hover {
        background-color: #f6f8fb !important;
    }
}

.mat-mdc-form-field-wrapper {
    margin-bottom: 0.25em;
}

.mat-form-field-appearance-outline.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float .mdc-floating-label,
.mat-form-field-appearance-outline.mat-mdc-form-field-can-float .mat-mdc-input-server:focus + .mdc-floating-label-wrapper .mdc-floating-label {
    transform: translateY(-1.30em) scale(0.75) !important;
}

.input-search-select {
    font-size: 13px !important;
}

.mat-form-field-appearance-outline .mdc-notched-outline__leading {
    border-radius: 8px 0 0 8px !important;
}

.mat-form-field-appearance-outline .mdc-notched-outline__trailing {
    border-radius: 0 8px 8px 0 !important;
}

.mat-mdc-checkbox-background, .mat-mdc-checkbox-frame {
    border-radius: 4px !important;
}

.mat-form-field-appearance-outline .mdc-notched-outline__leading, .mat-form-field-appearance-outline .mdc-notched-outline__trailing {
    min-width: 8px !important;
}

.mat-form-field-appearance-outline input,
.mat-form-field-appearance-outline textarea,
.mat-mdc-select-trigger {
    padding-left: 6px !important;
}

.mdc-floating-label {
    font-size: 13px #{'!important'};
    //padding-left: 6px #{'!important'};
    line-height: 1.2 #{'!important'};
}

.mat-mdc-form-field-prefix .mat-mdc-icon-button .mat-mdc-icon, .mat-mdc-form-field-suffix .mat-mdc-icon-button .mat-mdc-icon {
    height: 1.5rem;
    line-height: 1.5rem;
}


/* -------------------------------------------------------------------------- */
/*  @ Form fields
/* -------------------------------------------------------------------------- */

/* "outline" appearance */
.mat-mdc-form-field.mat-form-field-appearance-outline {
    --mat-mdc-form-field-floating-label-scale: 0.8;

    .mat-mdc-text-field-wrapper {
        flex: none;
    }

    .mat-mdc-icon-button {
        width: 24px !important;
        height: 24px !important;
        min-height: 24px !important;
        min-width: 24px !important;
        font-size: 24px !important;

        .mat-icon {
            width: 20px !important;
            height: 20px !important;
            min-height: 20px !important;
            min-width: 20px !important;
            font-size: 20px !important;
            color: themes.$color-neutral-default !important;
            --k-svg-color: themes.$color-neutral-default !important;
        }
    }

    /* Disabled */
    &.mat-form-field-disabled {
        input {
            color: themes.$color-neutral-darker !important;
        }

        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
            color: #E8E8E8 !important;
            background-color: themes.$color-neutral-lightest !important;
            border-color: #E8E8E8 !important;
        }

        .mdc-notched-outline {
            border-radius: 8px;
        }

        .mdc-floating-label {
            color: themes.$color-neutral-default !important;
        }

        .mat-mdc-form-field-icon-suffix,
        .mat-mdc-form-field-icon-prefix {
            z-index: 2;
        }
    }


    /* Invalid */
    &.mat-form-field-invalid {

        .mdc-floating-label {
            color: themes.$color-ui-error-default !important;
        }

        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
            border-color: themes.$color-ui-error-default !important;
            border-width: 2px !important;
        }
    }

    /* Focused */
    &.mat-focused:not(.mat-form-field-invalid) {

        /* Primary */
        &.mat-primary {

            .mdc-notched-outline__leading,
            .mdc-notched-outline__notch,
            .mdc-notched-outline__trailing {
                border-color: themes.$color-brand-colors-main-darker !important;
            }
        }

        /* Accent */
        &.mat-accent {

            .mdc-notched-outline__leading,
            .mdc-notched-outline__notch,
            .mdc-notched-outline__trailing {
                border-color: themes.$color-brand-colors-main-darker !important;
            }
        }
    }

    &:not(.mat-focused):not(.mat-form-field-invalid):not(.mat-form-field-disabled) {

        .mat-mdc-text-field-wrapper {

            .mat-mdc-form-field-flex {

                .mdc-notched-outline {

                    .mdc-notched-outline__leading,
                    .mdc-notched-outline__notch,
                    .mdc-notched-outline__trailing {
                        @apply border-slate-300 dark:border-slate-500 #{'!important'};
                        border-color: themes.$color-neutral-light !important;
                    }
                }
            }
        }
    }

    /* Remove the extra border on the right side of the notch */
    /* Tailwind's global border setter causes this issue */
    .mat-mdc-text-field-wrapper {

        .mat-mdc-form-field-flex {

            .mdc-notched-outline {

                .mdc-notched-outline__notch {
                    border-right-style: none !important;
                    border-left-style: none !important;
                }
            }
        }
    }


    &.mat-mdc-form-field-type-mat-date-range-input {
        .mat-mdc-form-field-infix {
            display: flex;
            flex-direction: row;

            .mat-date-range-input-start-wrapper {
                // flex: auto;
            }
        }

        .mat-date-range-close,
        .mat-icon {
            width: 18px;
            max-width: 18px;
            height: 18px;
            max-height: 18px;
            font-size: 18px;
            line-height: 18px;
        }
    }
}

/* "fill" appearance */
.mat-mdc-form-field.mat-form-field-appearance-fill.k-legacy-money {
    .mat-mdc-text-field-wrapper {
        background: transparent;
        padding: 0 !important;
        border-radius: 0;
    }

    .mat-mdc-form-field-focus-overlay, .mat-mdc-form-field-focused {
        background: transparent;
    }

    .mat-mdc-form-field-infix {
        min-height: 20px !important;
    }
    .mat-mdc-form-field-error-wrapper {
        padding-bottom: 0px !important;
        margin-top: 0px !important;
    }
}

.k-legacy-money {
    .mat-mdc-input-element {
        min-height: 20px !important;
        padding: 0 !important;
    }

    .mat-mdc-form-field-infix {
        padding: 0 !important;
        border-top: 0 !important;
    }

    .mat-mdc-form-field-wrapper {
        padding-bottom: 0 !important;
        margin-bottom: 0 !important;
    }

    .mat-mdc-form-field-underline {
        bottom: 0 !important;
    }
}

/* -------------------------------------------------------------------------- */
/*  @ Select
/* -------------------------------------------------------------------------- */
.mat-mdc-select {
    display: inline-flex !important;

    .mat-mdc-select-placeholder {
        transition: none !important;
        @apply text-hint #{'!important'};
    }

    .mat-mdc-select-trigger {

        .mat-mdc-select-value {
            position: relative;
            display: flex;
            max-width: none;

            .mat-mdc-select-value-text {
                display: inline-flex;

                > * {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    .mat-mdc-select-arrow-wrapper {
        transform: translateY(0) !important;

        .mat-mdc-select-arrow {
            margin: 0 0 0 8px;
        }
    }

    .mat-mdc-form-field-infix {
        max-height: 42px;;
    }

    mat-select-trigger {
        img.bank-brand {
            width: 20px;
            height: 20px;
            display: inline-block;
        }
    }
}

.mat-mdc-select-panel, .mat-mdc-autocomplete-panel {
    padding-top: 0px !important;
    padding-bottom: 0px !important;

    .mat-mdc-option {
        display: flex !important;
        justify-content: start !important;
        align-items: center !important;
        height: auto !important;
        width: 100% !important;
        min-height: 40px !important;

        .mdc-list-item__primary-text {
            @apply font-typography-text-medium;
            color: themes.$color-neutral-dark #{'!important'};
        }

        &.mat-mdc-option-active, &.mdc-list-item--selected {
            opacity: 1 !important;
            background: transparent !important; //themes.$color-brand-colors-main-lightest-white !important;

            &.mdc-list-item--disabled {
                // background-color: #e5e9ea !important;
                // border-color: themes.$color-neutral-light !important;
                opacity: .38 !important;
            }

            .mdc-list-item__primary-text {
                background: transparent;
                @apply font-typography-text-medium;
                color: themes.$color-neutral-dark #{'!important'};
            }
        }
    }
}

.mat-mdc-select-arrow svg {
    display: none;
}

.mat-select-type-price {
    min-width: 100px;
}

/* -------------------------------------------------------------------------- */
/*  @ Slide Toggle
/* -------------------------------------------------------------------------- */
.mat-mdc-slide-toggle {
    --mdc-switch-unselected-track-color: #d8d8d8;
    --mdc-switch-selected-track-color: themes.$color-brand-colors-main-darker;

    .mdc-form-field > label {
        margin-left: 6px;
    }

    label {
        font-family: themes.$inter;
        font-size: 13px !important;
        color: themes.$color-neutral-darker !important;
    }

    .mdc-switch:enabled {
        .mdc-switch__track {
            &::before {
                background: #d8d8d8 !important;
            }

            &::after {
                background: themes.$color-brand-colors-main-lighter !important;
            }
        }

        .mdc-switch__handle {
            .mdc-switch__shadow {
                box-shadow: 0 1px 2px 0 #979797;
            }

            .mdc-switch__icons {
                display: none;
            }
        }

        &.mdc-switch--unselected {
            .mdc-switch__handle {
                &::after {
                    background-color: #f8f8f8 !important;
                }
            }
        }

        &.mdc-switch--selected {
            .mdc-switch__handle {
                &::after {
                    background-color: themes.$color-brand-colors-main-darker !important;
                }
            }
        }
    }
}

/* -------------------------------------------------------------------------- */
/*  @ Checkbox
/* -------------------------------------------------------------------------- */
.mat-mdc-checkbox, .mat-pseudo-checkbox {
    display: inline-flex !important;

    .mdc-form-field {
        padding-right: 12px;
        line-height: 1.5;
    }

    &.table-checkbox {
        .mdc-form-field {
            padding-right: 0 !important;
        }
    }

    label {
        color: #2c4151;
        font-size: 13px;
    }

    .mdc-checkbox__background {
        border-color: themes.$color-neutral-light !important;
        border-width: 0.15rem !important;
        border-radius: 4px !important;
    }

    &.mat-mdc-checkbox-checked, &[ng-reflect-indeterminate="true"] {
        .mdc-checkbox__background {
            background-color: themes.$color-brand-colors-main-darker !important;
            border-color: themes.$color-brand-colors-main-darker !important;
        }
    }

    &.mat-mdc-checkbox-disabled {
        .mdc-checkbox__background {
            background-color: #e5e9ea !important;
            border-color: themes.$color-neutral-light !important;
        }

        &.mat-mdc-checkbox-checked, &[ng-reflect-indeterminate="true"] {
            .mdc-checkbox__background {
                background-color: themes.$color-neutral-default !important;
                border-color: themes.$color-neutral-default !important;
            }
        }
    }
}

.mat-mdc-option .mat-pseudo-checkbox {
    margin-right: 8px !important;
}


/* -------------------------------------------------------------------------- */
/*  @ Radio group
/* -------------------------------------------------------------------------- */

.mat-mdc-radio-button {
    .mdc-radio__background {
        .mdc-radio__outer-circle, .mdc-radio__inner-circle {
            border-color: themes.$color-neutral-light !important;
        }
    }

    &.mat-mdc-radio-checked {
        .mdc-radio__background {
            .mdc-radio__outer-circle, .mdc-radio__inner-circle {
                border-color: themes.$color-brand-colors-main-darker !important;
            }
        }
    }

    label {
        padding-left: 0;
    }
}


/* -------------------------------------------------------------------------- */
/*  @ Slider
/* -------------------------------------------------------------------------- */
.mat-mdc-slider {
    .mdc-slider__track {
        height: 2px !important;

        .mdc-slider__track--inactive, .mdc-slider__track--active {
            height: 2px !important;
        }
    }

    .mdc-slider__thumb-knob {
        border-width: 7px !important;
        height: 14px !important;
        width: 14px !important;
    }
}


/* -------------------------------------------------------------------------- */
/*  @ Calendar picker
/* -------------------------------------------------------------------------- */

.mat-calendar-next-button, .mat-calendar-previous-button {
    width: 40px;

    &:disabled {
        color: themes.$color-neutral-light !important;
    }
}

.mat-calendar-period-button {
    .mat-calendar-arrow {
        border-top-color: themes.$color-neutral-darker;
    }
}


ngx-mat-datetime-content {
    .mat-calendar-body-cell {
        position: relative !important;
    }
}

mat-datepicker-content {
    .mat-calendar-body-cell {
        position: absolute !important;
        height: 100%;
    }
}

input.hide-input-date-picker {
    position: absolute !important;
    bottom: 0;
    left: -10px;
    opacity: 0;
    width: 1px;
    height: 0px;
}

.mat-date-range-input-mirror {
    padding-left: 6px;
}

.mat-date-range-input-start-wrapper {
    padding-right: 6px;
}


// Phone
.iti {
    &.iti--allow-dropdown {
        width: 100%;
    }
    input {
        padding-left: 52px !important;
    }
}
