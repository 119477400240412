/* 1. Overrides Kiwid */
@use 'overrides/breakpoints';
@use 'overrides/typography';
@use 'overrides/btn';
@use 'overrides/mat-menu';
@use 'overrides/k-grid';
@use 'overrides/mat-chip';
@use 'overrides/general';
@use 'overrides/forms';
@use 'overrides/navigation';
@use 'overrides/avatars';
@use 'overrides/card';
@use 'overrides/mat-badge';
@use 'overrides/icons';
@use "overrides/mat-tab";
@use "overrides/tooltip";
@use "overrides/payment-status-icon";
@use "overrides/mat-accordion";
