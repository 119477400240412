@use "../themes";

.icon-transaction-status {
    width: 18px !important;
    height: 18px !important;
    min-width: 18px !important;
    min-height: 18px !important;
    max-width: 18px !important;
    max-height: 18px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9px;

    > .mat-icon {
        width: 12px !important;
        height: 12px !important;
        min-width: 12px !important;
        min-height: 12px !important;
        max-width: 12px !important;
        max-height: 12px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.c-done-success-payment {
        background: themes.$color-ui-success-default;
        color: white;

        svg {
            path {
                stroke: white !important;
                stroke-width: 4 !important;
            }
        }
    }

    &.c-pending-today-payment {
        background: themes.$color-ui-warning-default;
        color: white;

        svg {
            path {
                fill: white !important;
            }
        }
    }

    &.c-pending-before-payment {
        background: themes.$color-ui-error-darker;

        svg {
            path {
                fill: white !important;
            }
        }
    }

    &.c-refused-payment {
        background: themes.$color-ui-error-default;

        svg {
            path {
                stroke: white !important;
                stroke-width: 3 !important;
            }
        }
    }

    &.c-refunded-payment {
        background: themes.$color-brand-colors-secondary-default;

        svg {
            path {
                fill: white !important;
            }
        }
    }

    &.c-dispute-chargeback-payment {
        background: themes.$color-ui-warning-darker;

        svg {
            path {
                stroke: white !important;
                stroke-width: 3 !important;
            }
        }
    }

    &.c-chargeback-payment {
        background: themes.$color-brand-colors-secondary-darker;

        svg {
            path {
                stroke: white !important;
                stroke-width: 3 !important;
            }
        }
    }

    &.c-dark-gray-payment {
        background: themes.$color-neutral-default;

        svg {
            path {
                stroke: white !important;
                stroke-width: 3 !important;
            }
        }
    }

    &.c-blue-payment {
        background: themes.$color-brand-colors-main-darker;

        svg {
            path {
                stroke: white !important;
                stroke-width: 3 !important;
            }
        }
    }
}
