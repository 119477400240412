@use '../themes';

.mat-mdc-tab-group {

    &.padding {
        .mat-mdc-tab-header {
            .mat-tab-label {
                &:first-child {
                    margin-left: 24px;
                }

                &:last-child {
                    margin-right: 24px;
                }
            }
        }
    }

    &.tabs-left {
        .mat-tab-labels {
            justify-content: left;
        }
    }

    .mat-mdc-tab-header {
        //overflow: auto;

        .mat-mdc-tab-label-container {
            margin: 0;

            .mat-mdc-tab-list {
                padding: 0 32px;
            }
        }

        &::-webkit-scrollbar {
            display: none;
        }

        .mat-mdc-tab-header-pagination {
            //display: none;
            box-shadow: none;

            .mat-mdc-tab-header-pagination-chevron {
                border-color: themes.$color-neutral-dark;
            }

            &.mat-mdc-tab-header-pagination-disabled {
                .mat-mdc-tab-header-pagination-chevron {
                    border-color: themes.$color-neutral-light;
                }
            }
        }

        .mat-tab-label-container {
            //min-width: 100%;
            //width: max-content;
            //overflow: unset;
            overflow-x: auto;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .mat-mdc-tab {
            .mdc-tab__content {
                font-family: themes.$inter;
                font-size: 13px;
                font-weight: themes.$regular;
                color: themes.$color-neutral-darker;
                text-decoration: none;
                text-transform: uppercase;
                opacity: 1;

                cursor: pointer;

                &:hover {
                    color: themes.$color-brand-colors-main-darker;
                }
            }

            &.mdc-tab--active{
                .mdc-tab__content {
                    font-weight: themes.$semiBold !important;
                    color: themes.$color-brand-colors-main-darker !important;
                }

                .mdc-tab-indicator {

                }
            }
        }



        .mat-ink-bar {
            background-color: themes.$color-brand-colors-main-darker;
            height: 3px;
        }
    }

    .mat-mdc-tab-body-content {
        padding: 0;
    }
}

.mat-mdc-tab-group.mat-primary .mat-tab-label:not(.mat-tab-disabled):focus {
    background-color: transparent !important;
}

.mat-drawer-container {
    color: themes.$color-neutral-darker;
}

.tab-inner {
    .mat-tab-list {
        background: #f9f6f6;
    }
    .mat-tab-label-active {
        color: #ffffff !important;
        padding-top: 12px !important;
    }
    .mat-ink-bar {
        height: 100% !important;
    }
    .mat-tab-label {
        z-index: 1 !important;
    }
}

.mat-ink-bar {
    background-color: themes.$color-brand-colors-main-darker !important;
    height: 3px;
}
