// grid form
.k-grid {
    display: grid;
    grid-template-columns: repeat(10, 1fr);

    //@media screen and (max-width: 599px) {
    //    & {
    //        grid-template-columns: 1fr;
    //    }
    //}

    &.k-g-10 {
        gap: 10px;
    }

    &.k-gc-10 {
        column-gap: 10px;
    }

    &.k-gr-10 {
        row-gap: 10px;
    }

    &.k-g-20 {
        gap: 20px;
    }

    &.k-g-24 {
        gap: 24px;
    }

    &.k-g-28 {
        gap: 28px;
    }

    &.k-gc-20 {
        column-gap: 20px;
    }

    &.k-gr-20 {
        row-gap: 20px;
    }

    &.k-gc-24 {
        column-gap: 24px;
    }

    &.k-gr-24 {
        row-gap: 24px;
    }

    &.k-g-30 {
        gap: 30px;
    }

    &.k-gc-30 {
        column-gap: 30px;
    }

    &.k-gr-30 {
        row-gap: 30px;
    }

    .k-grid-10 {
        grid-column-start: span 1;
        @media screen and (max-width: 599px) {
            & {
                grid-column-start: span 10;
            }
        }
    }

    .k-grid-20 {
        grid-column-start: span 2;
        @media screen and (max-width: 599px) {
            & {
                grid-column-start: span 10;
            }
        }
    }

    .k-grid-25 {
        grid-column-start: span 2.5;
        @media screen and (max-width: 599px) {
            & {
                grid-column-start: span 10;
            }
        }
    }

    .k-grid-30 {
        grid-column-start: span 3;
        @media screen and (max-width: 599px) {
            & {
                grid-column-start: span 10;
            }
        }
    }

    .k-grid-40 {
        grid-column-start: span 4;
        @media screen and (max-width: 599px) {
            & {
                grid-column-start: span 10;
            }
        }
    }

    .k-grid-50 {
        grid-column-start: span 5;
        @media screen and (max-width: 599px) {
            & {
                grid-column-start: span 10;
            }
        }
    }

    .k-grid-60 {
        grid-column-start: span 6;
        @media screen and (max-width: 599px) {
            & {
                grid-column-start: span 10;
            }
        }
    }

    .k-grid-70 {
        grid-column-start: span 7;
        @media screen and (max-width: 599px) {
            & {
                grid-column-start: span 10;
            }
        }
    }

    .k-grid-80 {
        grid-column-start: span 8;
        @media screen and (max-width: 599px) {
            & {
                grid-column-start: span 10;
            }
        }
    }

    .k-grid-90 {
        grid-column-start: span 9;
        @media screen and (max-width: 599px) {
            & {
                grid-column-start: span 10;
            }
        }
    }

    .k-grid-100 {
        grid-column-start: span 10;
    }
}

.k-grid-second {
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    &.k-g-10 {
        gap: 10px;
    }

    &.k-gc-10 {
        column-gap: 10px;
    }

    &.k-gr-10 {
        row-gap: 10px;
    }

    &.k-g-20 {
        gap: 20px;
    }

    &.k-g-28 {
        gap: 28px;
    }

    &.k-gc-20 {
        column-gap: 20px;
    }

    &.k-gr-20 {
        row-gap: 20px;
    }

    &.k-g-30 {
        gap: 30px;
    }

    &.k-gc-30 {
        column-gap: 30px;
    }

    &.k-gr-30 {
        row-gap: 30px;
    }

    .k-grid-1 {
        grid-column-start: span 1;

        @media screen and (max-width: 599px) {
            & {
                grid-column-start: span 12;
            }
        }
    }

    .k-grid-2 {
        grid-column-start: span 2;

        @media screen and (max-width: 599px) {
            & {
                grid-column-start: span 12;
            }
        }
    }

    .k-grid-3 {
        grid-column-start: span 3;

        @media screen and (max-width: 599px) {
            & {
                grid-column-start: span 12;
            }
        }
    }

    .k-grid-4 {
        grid-column-start: span 4;

        @media screen and (max-width: 599px) {
            & {
                grid-column-start: span 12;
            }
        }
    }

    .k-grid-5 {
        grid-column-start: span 5;

        @media screen and (max-width: 599px) {
            & {
                grid-column-start: span 12;
            }
        }
    }

    .k-grid-6 {
        grid-column-start: span 6;

        @media screen and (max-width: 599px) {
            & {
                grid-column-start: span 12;
            }
        }
    }

    .k-grid-7 {
        grid-column-start: span 7;

        @media screen and (max-width: 599px) {
            & {
                grid-column-start: span 12;
            }
        }
    }

    .k-grid-8 {
        grid-column-start: span 8;

        @media screen and (max-width: 599px) {
            & {
                grid-column-start: span 12;
            }
        }
    }

    .k-grid-8 {
        grid-column-start: span 8;

        @media screen and (max-width: 599px) {
            & {
                grid-column-start: span 12;
            }
        }
    }

    .k-grid-9 {
        grid-column-start: span 8;

        @media screen and (max-width: 599px) {
            & {
                grid-column-start: span 12;
            }
        }
    }

    .k-grid-10 {
        grid-column-start: span 10;

        @media screen and (max-width: 599px) {
            & {
                grid-column-start: span 12;
            }
        }
    }

    .k-grid-11 {
        grid-column-start: span 11;

        @media screen and (max-width: 599px) {
            & {
                grid-column-start: span 12;
            }
        }
    }

    .k-grid-12 {
        grid-column-start: span 12;
    }
}